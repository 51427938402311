import { Button, Checkbox, Divider, Dropdown, Flex, Header, Input, ProviderConsumer, Text } from '@fluentui/react-northstar';
import React, { useCallback, useState, useEffect } from 'react';
import { EmailPageWrapper } from '../kb/EmailPageWrapper';
import { Breadcrumbs } from '../shared/components/BreadcrumbNavigation';
import { CONTACT_ADMIN, DEFAULT_API_STATUS, DONE, EMAIL, ERROR, LICENSE_FEATURE, LOADING, REFRESH_SESSION, SETTINGS, SOMETHING_WENT_WRONG, UNAUTHORIZED_ACCESS, ADMINISTRATORS, toolbarOptions } from '../shared/utils/constants';
import { dementionify, mentionify, removeHtmlTags, removeWhiteSpaces, toTitleCase } from '../shared/utils/helper';
import { Communication } from '@fluentui/react-teams';
import { Providers } from '@microsoft/mgt-element/dist/es6/providers/Providers';
import { platformService } from '../shared/services/platform.service';
import { CheckLogin } from '../shared/components/CheckLogin';
import { Helmet } from 'react-helmet';
import { HtmlEditorComponent } from '../shared/components/Wysiwyg/Editor';
import { useBoolean } from '@fluentui/react-hooks';
import { LoadingScreen } from '../shared/components/LoadingScreen';
import { MobileSettingsView } from '../automation/AutomationTable';
import { getCachedFeature, hasConsentFeature } from '../shared/cache/FeatureCache';
import { FeatureConsentNeeded, UpgradeNeeded } from '../shared/components/UpgradeNeeded';
import { CheckLicense } from '../shared/components/License/CheckLicense';
import MgtCss from '../mgt.module.css';
import { PeoplePicker, PersonType, UserType } from '@microsoft/mgt-react';
import { ThemeColorScheme } from '../shared/common/TeamsTheme'; import { useTranslation } from 'react-i18next';
import { appState } from '../AppState';
import { LabelElement } from '../shared/components/Modal/Modal';
import { getTicketTokenMentionList } from '../shared/common/CommonHelper';
import { Link } from 'react-router-dom';


export const NotificationCenter = () => {
  const appContextState = appState();
  const { t } = useTranslation();
  const api = new platformService();
  const [apiStatus, setApiStatus] = useState <APIStatus> (DEFAULT_API_STATUS as APIStatus);
  const [prevData, setPrevData] = useState({});
  const [isConnected, setIsConnected] = useState(false);
  const [connectedEmail, setConnectedEmail] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [responseSubject, setResponseSubject] = useState('');

  const [locale, setLocale] = useState('en-US');

  const [hasEmailConsent, setHasEmailConsent] = useState(true);
  const [connectedId, setConnectedId] = useState('');
  const [useDeflection, { toggle: toggleUseDeflection, setTrue: setTrueDeflection, setFalse: setFalseDeflection }] = useBoolean(false);
  const [excludeFooter, { toggle: toggleExcludeFooter, setTrue: setTrueExcludeFooter, setFalse: setFalseExcludeFooter }] = useBoolean(false);

  const [changeStatusEmailNotification, setChangeStatusEmailNotification] = useState(false);
  const [changeAssigneeEmailNotification, setChangeAssigneeEmailNotification] = useState(false);
  const [approvalAddedEmailNotification, setApprovalAddedEmailNotification] = useState(false);
  const [approvalApprovedEmailNotification, setApprovalApprovedEmailNotification] = useState(false);
  const [approvalRejectedEmailNotification, setApprovalRejectedEmailNotification] = useState(false);
  const [sendCreatedEmailMessageResponse, setSendCreatedEmailMessageResponse] = useState(false);
  const [emailConfigDetail, setEmailConfigDetail] = useState<any>();

  const [changeStatusTeamsNotification, setChangeStatusTeamsNotification] = useState(false);
  const [changeAssigneeTeamsNotification, setChangeAssigneeTeamsNotification] = useState(false);
  const [approvalAddedTeamsNotification, setApprovalAddedTeamsNotification] = useState(false);
  const [approvalApprovedTeamsNotification, setApprovalApprovedTeamsNotification] = useState(false);
  const [approvalRejectedTeamsNotification, setApprovalRejectedTeamsNotification] = useState(false);
  const [sendCreatedTeamsMessageResponse, setSendCreatedTeamsMessageResponse] = useState(false);

  const [cultures, setCultures] = useState([]);
  const [isEmailConnector, setIsEmailConnector] = useState(true);
  const [isNotificationCenter, setIsNotificationCenter] = useState(true);
  const [multiInbox, setIsMultiInbox] = useState(false);
  const [multiDept, setIsMultiDept] = useState(false);
  const isAdmin = appContextState.userRoles.roles.includes(ADMINISTRATORS);
  const [enableMultiDepartmentPicker, { toggle: toggleEnableMultiDepartmentPicker, setTrue: setTrueMultiDepartmentPicker, setFalse: setFalseMultiDepartmentPicker }] = useBoolean(false);
  const [multiDepartmentPickerMessage, setMultiDepartmentPickerMessage] = useState(""); 
  const [mentionList, setMentionList] = useState({
    separator: ' ',
    trigger: '{',
    suggestions: []
  });
  const [minFileSize, setMinFileSize] = useState<number>(0);

  useEffect(()=>{
    setChangeStatusEmailNotification(isConnected ? emailConfigDetail.ChangeStatusEmailNotification : false);
    setChangeAssigneeEmailNotification(isConnected ? emailConfigDetail.ChangeAssigneeEmailNotification : false);
    setApprovalAddedEmailNotification(isConnected ? emailConfigDetail.ApprovalAddedEmailNotification : false);
    setApprovalApprovedEmailNotification(isConnected ? emailConfigDetail.ApprovalApprovedEmailNotification : false);
    setApprovalRejectedEmailNotification(isConnected ? emailConfigDetail.ApprovalRejectedEmailNotification : false);
    setSendCreatedEmailMessageResponse(isConnected ? emailConfigDetail.SendCreatedEmailMessageResponse : false);
    
  },[isConnected]);

  const fetchSystemSetting = useCallback(async () => {
    setApiStatus({ ...apiStatus, msg: t('notification-center.fetching-system-setting') });
    try {
      const [emailConfig, teamsSafe, emailConnector, multiEmailInbox, multiDepartment, notificationCenter]
        = await Promise.all([
          api.getEmailConnectorConfiguration(),
          api.getTeamsSafe(`?$filter=SubscriptionId ne null and SubscriptionId ne ''`),
          getCachedFeature(LICENSE_FEATURE.EmailConnector),
          getCachedFeature(LICENSE_FEATURE.MultiEmailInbox),
          getCachedFeature(LICENSE_FEATURE.MultiDepartment),
          getCachedFeature(LICENSE_FEATURE.NotificationCenter)
        ]);

      const { data } = emailConfig;
      setEmailConfigDetail(data);
      setIsEmailConnector(emailConnector);
      setIsMultiInbox(multiEmailInbox);
      setIsMultiDept(multiDepartment);
      setIsNotificationCenter(notificationCenter);

      delete data['@odata.context'];
      setPrevData(data);
      setHasEmailConsent(await hasConsentFeature('EMAIL_CONNECTOR'));

      if (!data.ResponseMessage || data.ResponseMessage.trim() == '') {
        data.ResponseMessage = `${t('notification-center.received-your-request')}`;
      }

      data.UseTicketDeflection ? setTrueDeflection() : setFalseDeflection();
      data.ExcludeFooter ? setTrueExcludeFooter() : setFalseExcludeFooter();
      data.EnableMultiDepartmentPicker ? setTrueMultiDepartmentPicker() : setFalseMultiDepartmentPicker();
      
      setMultiDepartmentPickerMessage(data.MultiDepartmentPickerMessage || t('bot-configuration.enduser-select-team-default'));
      setChangeStatusTeamsNotification(data.ChangeStatusTeamsNotification);
      setChangeAssigneeTeamsNotification(data.ChangeAssigneeTeamsNotification);
      setApprovalAddedTeamsNotification(data.ApprovalAddedTeamsNotification);
      setApprovalApprovedTeamsNotification(data.ApprovalApprovedTeamsNotification);
      setApprovalRejectedTeamsNotification(data.ApprovalRejectedTeamsNotification);
      setSendCreatedTeamsMessageResponse(data.SendCreatedTeamsMessageResponse);
      setConnectedEmail(data.EmailAddress);
      setResponseMessage(data.ResponseMessage);
      setResponseSubject(data.ResponseSubject);
      setLocale(data.DefaultLocale);
      setMinFileSize((data.MinimumAttachmentFileSize || 0) / 1024);

      setIsConnected(prev => {
        return (!multiEmailInbox && data.EmailAddress !== null && data.EmailAddress !== undefined && data.EmailAddress !== '' && data.SubscriptionId !== null && data.SubscriptionId !== undefined && data.SubscriptionId !== '') ||
          (multiEmailInbox && teamsSafe.length > 0);
      });

      const result = await api.getCultures();
      const available = result.data.value.map(l => {
        const label = new Intl.DisplayNames(l.Code, { type: 'language', languageDisplay: 'dialect' }).of(l.Code);
        return {
          header: label.charAt(0).toUpperCase() + label.slice(1),
          key: l.Code
        }
      });
      available.push({ header: new Intl.DisplayNames('en', { type: 'language', languageDisplay: 'dialect' }).of('en'), key: 'en-US' });
      available.sort((a, b) => a.header.localeCompare(b.header));
      setCultures(available);
    } catch (err: any) {
      setApiStatus({ ...apiStatus, status: ERROR, errCode: err['response']?.status });
    } finally {
      setApiStatus({ ...apiStatus, status: DONE });
    }

    let mentionList = await getTicketTokenMentionList({}, true);
    setMentionList(mentionList);
  }, []);

  const loadPage = () => {
    if (!isAdmin) {
      setApiStatus({ ...apiStatus, status: ERROR, errCode: 401 });
    } else {
      fetchSystemSetting();
    }
  };

  const navs: Breadcrumbs = {
    breadcrumbs: [
      {
        title: toTitleCase(SETTINGS),
        link: '/settings'
      },
      {
        title: isNotificationCenter ? t('notification-center.notification-center') : t('notification-center.email-connector'),
        link: ''
      }
    ]
  };

  const onChangeMailbox = async (e: any) => {
    if (e.detail.length === 0) {
      setConnectedId("");
      setConnectedEmail("");
    } else {
      setConnectedId(e.detail[0].id);
      setConnectedEmail(e.detail[0].scoredEmailAddresses && e.detail[0].scoredEmailAddresses.length > 0 ? e.detail[0].scoredEmailAddresses[0].address : e.detail[0].userPrincipalName);
    }
  };

  const connectToAccount = async () => {
    setApiStatus({
      status: LOADING,
      msg: t('notification-center.connecting-mailbox'),
      errCode: 0
    });
    try {
      await api.subscribeToEmail(connectedId, connectedEmail);
      loadPage();
    } catch (err: any) {
      setApiStatus({
        ...apiStatus,
        status: ERROR,
        errCode: err['response']?.status
      });
    }
  };

  const saveSettings = async () => {
    setApiStatus({
      status: LOADING,
      msg: t('notification-center.saving-settings'),
      errCode: 0
    });
    try {
      const payload = {
        ...prevData,
        ResponseMessage: responseMessage,
        ResponseSubject: responseSubject,
        UseTicketDeflection: useDeflection,
        ExcludeFooter: excludeFooter,
        MinimumAttachmentFileSize: minFileSize * 1024,
        DefaultLocale: locale,
        EnableMultiDepartmentPicker: enableMultiDepartmentPicker,
        MultiDepartmentPickerMessage: multiDepartmentPickerMessage,
        ChangeStatusEmailNotification: changeStatusEmailNotification,
        ChangeAssigneeEmailNotification: changeAssigneeEmailNotification,
        ApprovalAddedEmailNotification: approvalAddedEmailNotification,
        ApprovalApprovedEmailNotification: approvalApprovedEmailNotification,
        ApprovalRejectedEmailNotification: approvalRejectedEmailNotification,
        SendCreatedEmailMessageResponse: sendCreatedEmailMessageResponse,

        ChangeStatusTeamsNotification: changeStatusTeamsNotification,
        ChangeAssigneeTeamsNotification: changeAssigneeTeamsNotification,
        ApprovalAddedTeamsNotification: approvalAddedTeamsNotification,
        ApprovalApprovedTeamsNotification: approvalApprovedTeamsNotification,
        ApprovalRejectedTeamsNotification: approvalRejectedTeamsNotification,
        SendCreatedTeamsMessageResponse: sendCreatedTeamsMessageResponse
      };

      await api.updateEmail(payload);

      setApiStatus({ ...apiStatus, status: DONE });
      setPrevData(payload);
    } catch (err: any) {
      setApiStatus({ ...apiStatus, status: ERROR, errCode: err['response']?.status });
    }
  };

  const disconnectMailbox = async () => {
    setApiStatus({
      status: LOADING,
      msg: t('notification-center.disconnecting-mailbox'),
      errCode: 0
    });
    try {
      const payload = {
        ...prevData,
        SubscriptionId: null,
        ClientState: null,
        UserId: null,
        EmailAddress: '',
        HasConsented: true,
        ExpirationDate: null
      };
      const res = await api.unsubscribeToEmail();
      setApiStatus({ ...apiStatus, status: DONE });
      if (res.status === 200) {
        setIsConnected(false);
        setConnectedEmail("");
        setConnectedId("");
        setPrevData(payload);
      }
    } catch (err: any) {
      setApiStatus({ ...apiStatus, status: ERROR, errCode: err['response']?.status });
    }
  };

  const errConfig = {
    fields: {
      title: apiStatus.errCode === 401 ? UNAUTHORIZED_ACCESS : SOMETHING_WENT_WRONG,
      desc: CONTACT_ADMIN
    }
  };

  const ConnectHeaderText = (globalTheme) => {
    return <>
      {!isConnected && 
        <>
          {isNotificationCenter && <h3 className='ui-header bd bx pb-2 defaultStyle'>{t('notification-center.connect-to-an-Office')}</h3>}
          {!isNotificationCenter && <Header className="pb-2" style={{margin:'0'}} content={t('notification-center.connect-to-an-Office')} />}
        </>
      }
      {!multiInbox && (<>
        <Flex
          style={{
            fontSize: '14px',
            lineHeight: '20px',
            color: '#616161',
            paddingBottom: '27.5px'
          }}
        >
          {t('notification-center.connect-tikit-to-an-existing')}
        </Flex>
        <Flex column gap="gap.medium">
          {!isConnected && <Flex gap="gap.medium" vAlign="center">
            <Flex
              style={{
                fontWeight: 600,
                fontSize: '16px',
                lineHeight: '22px',
                color: globalTheme.siteVariables.colorScheme.default.foreground
              }}
            >
              {t('notification-center.select-an-account')}
            </Flex>
          </Flex>}
          <Flex column gap="gap.medium">
            {!isConnected && (
              <div className="teams-input-med">
                <PeoplePicker userType={UserType.user} type={PersonType.person} selectionMode="single" className={`width-med height-small ${MgtCss.groupsPicker}`} selectionChanged={onChangeMailbox} />
              </div>
            )}
            {!isConnected ? (
              <Flex>
                <CheckLicense disable>
                  <Button
                    content={t('notification-center.connect-m365-mailbox')}
                    primary
                    disabled={connectedEmail === '' && connectedId === ''}
                    onClick={connectToAccount}
                  />
                </CheckLicense>
              </Flex>
            ) : (
              <>
                <Flex vAlign="center" gap="gap.small">
                  <Text
                    content={toTitleCase(EMAIL)}
                    style={{ color: '#686868' }}
                  />
                  <div className="teams-input-med">
                    <div>{connectedEmail}:</div>
                  </div>
                </Flex>
                <Flex>
                  <CheckLicense disable>
                    <Button
                      content={t('notification-center.disconnect-m365-mailbox')}
                      primary
                      onClick={disconnectMailbox}
                    />
                  </CheckLicense>
                </Flex>
              </>
            )}
          </Flex>
        </Flex>
      </>)}
      {(multiInbox && !isConnected) && (
      <Flex className="pb-2" style={{ fontSize: '14px', lineHeight: '20px', color: '#616161' }}>
        <span>
          {t('notification-center.multi-inbox-enabled-1')}
          <Link to='/settings/teams' style={{ color: globalTheme.siteVariables.colorScheme.brand.foregroundActive }}>{t('side-nav.teams')}</Link>
          {t('notification-center.multi-inbox-enabled-2')}
        </span>
        
        </Flex>)}
    </>;
  }

  const getPageContent = () => {
    return <>
      {apiStatus.status === ERROR ? (
        <>
          <Communication {...errConfig} />
          {apiStatus.errCode === 401 && (
            <Button
              content={REFRESH_SESSION}
              primary
              onClick={e => {
                Providers.globalProvider.login();
              }}
            />
          )}
        </>
      ) : (
        <>
          {!(isEmailConnector || isNotificationCenter) && (<UpgradeNeeded toolbarText={`${toTitleCase(SETTINGS)} > ${isNotificationCenter ? t('notification-center.notification-center') : t('notification-center.email-connector')}`} breadcrumbs={navs}
            headerText={t('notification-center.you-do-not-have-access')} subheaderText={t('notification-center.please-upgrade-your-plan')} />)}
          {(isEmailConnector || isNotificationCenter) && (
            <EmailPageWrapper
              title={isNotificationCenter ? t('notification-center.notification-center') : t('notification-center.email-connector')}
              breadcrumbs={navs}
              disableSave={(sendCreatedEmailMessageResponse && (removeWhiteSpaces(removeHtmlTags(responseMessage)) == "" || removeWhiteSpaces(removeHtmlTags(responseSubject)) == ""))}
              saveData={saveSettings}
            >
              <ProviderConsumer
                render={globalTheme => (
                  <Flex column style={{ padding: '0 32px 32px 32px', ...ThemeColorScheme(globalTheme.siteVariables) }}>
                    {!hasEmailConsent && (<FeatureConsentNeeded />)}
                    {!isNotificationCenter && ConnectHeaderText(globalTheme)}

                    {(isConnected && isEmailConnector && !isNotificationCenter) && (
                      <>
                        <Header className="pb-2" content={t('notification-center.global-settings')} />
                        <Header as="h3" content={<>{t('notification-center.email-connector-response-message')}<span style={{ color: 'red', marginLeft: 2, fontWeight: "bold" }}>*</span></>} className="pb-2 defaultStyle" />
                        {mentionList.suggestions.length>0 && 
                          <HtmlEditorComponent
                            mention={mentionList}
                            htmlString={mentionify(responseMessage,mentionList.suggestions)}
                            onChange={(data: any) => setResponseMessage(dementionify(data,mentionList.suggestions))}
                            wrapperClassName="htmleditor-wrapper"
                            editorClassName="editor--with-mention automation-editor-height"
                          />
                        }
                        
                      </>

                    )}

                    {isNotificationCenter && (
                      <div className='notificationSettingsContent'>
                        <div className='notificationSettingsContent-chk'>
                          <h3 className='ui-header bd bx pb-2 defaultStyle'>{t('notification-center.tva-header')}</h3>
                          <div className='notification-description'>
                            {t('notification-center.description-TVA')} <Link to='/settings/automation' style={{ color: globalTheme.siteVariables.colorScheme.brand.foregroundActive }}>{t('automation.automation')}.</Link>
                            {t('notification-center.description-TVA-2')}
                          </div>
                          <Checkbox label={t('notification-center.change-status')}
                            onChange={(_, e) => {
                              setChangeStatusTeamsNotification(e.checked);
                            }}
                            checked={changeStatusTeamsNotification} />
                          <Checkbox label={t('notification-center.change-assignee')} onChange={(_, e) => {
                            setChangeAssigneeTeamsNotification(e.checked);
                          }}
                            checked={changeAssigneeTeamsNotification} />
                          <Checkbox label={t('notification-center.approval-added')} onChange={(_, e) => {
                            setApprovalAddedTeamsNotification(e.checked);
                          }}
                            checked={approvalAddedTeamsNotification} />
                          <Checkbox label={t('notification-center.approval-approved')} onChange={(_, e) => {
                            setApprovalApprovedTeamsNotification(e.checked);
                          }}
                            checked={approvalApprovedTeamsNotification} />
                          <Checkbox label={t('notification-center.approval-rejected')} onChange={(_, e) => {
                            setApprovalRejectedTeamsNotification(e.checked);
                          }}
                            checked={approvalRejectedTeamsNotification} />
                          <Checkbox label={t('notification-center.send-response')} onChange={(_, e) => {
                            setSendCreatedTeamsMessageResponse(e.checked);
                          }}
                            checked={sendCreatedTeamsMessageResponse} />
                        </div>
                        {!isConnected && <div style={{marginTop:'36px'}}></div>}
                        {(isNotificationCenter) && ConnectHeaderText(globalTheme)}
                        <br />
                        <div className='notificationSettingsContent-chk' style={{ marginRight: "26px" }}>
                          <h3 className='ui-header bd bx pb-2 defaultStyle'>{t('notification-center.tikit-header')}</h3>
                          <div className='notification-description'>
                            {t('notification-center.description-tikit')}
                            <Link to='/settings/automation' style={{ color: globalTheme.siteVariables.colorScheme.brand.foregroundActive }}>{t('automation.automation')}.</Link>
                            {t('notification-center.description-tikit-2')}
                          </div>
                          <Checkbox disabled={!isConnected} label={t('notification-center.change-status')}
                            onChange={(_, e) => {
                              setChangeStatusEmailNotification(e.checked);
                            }}
                            checked={changeStatusEmailNotification} />
                          <Checkbox label={t('notification-center.change-assignee')} disabled={!isConnected} onChange={(_, e) => {
                            setChangeAssigneeEmailNotification(e.checked);
                          }}
                            checked={changeAssigneeEmailNotification} />
                          <Checkbox label={t('notification-center.approval-added')} disabled={!isConnected} onChange={(_, e) => {
                            setApprovalAddedEmailNotification(e.checked);
                          }}
                            checked={approvalAddedEmailNotification} />
                          <Checkbox label={t('notification-center.approval-approved')} disabled={!isConnected} onChange={(_, e) => {
                            setApprovalApprovedEmailNotification(e.checked);
                          }}
                            checked={approvalApprovedEmailNotification} />
                          <Checkbox label={t('notification-center.approval-rejected')} disabled={!isConnected} onChange={(_, e) => {
                            setApprovalRejectedEmailNotification(e.checked);
                          }}
                            checked={approvalRejectedEmailNotification} />
                          <Checkbox label={t('notification-center.send-response')} disabled={!isConnected} onChange={(_, e) => {
                            setSendCreatedEmailMessageResponse(e.checked);
                          }}
                            checked={sendCreatedEmailMessageResponse} />

                          <LabelElement label={t('notification-center.response-subject')} required={true} className="pt-2 pb-2" />
                          {mentionList.suggestions.length>0 && 
                            <HtmlEditorComponent readOnly={!sendCreatedEmailMessageResponse}
                              mention={mentionList}
                              htmlString={mentionify(responseSubject,mentionList.suggestions)}
                              onChange={(data: any) => setResponseSubject(removeHtmlTags(dementionify(data,mentionList.suggestions)))}
                              oneLine={true}
                              toolbarOptions={[]}
                              toolbarInline={{
                                inDropdown: false,
                                className: undefined,
                                component: undefined,
                                dropdownClassName: undefined,
                                options: []
                              }}
                              wrapperClassName="editor--no-toolbar"
                              editorClassName="editor--one-liner editor--with-mention"
                            />
                          }
                          <LabelElement label={t('notification-center.response-message')} required={true} className="pt-2 pb-2" />
                          {mentionList.suggestions.length>0 && 
                            <HtmlEditorComponent readOnly={!sendCreatedEmailMessageResponse}
                              mention={mentionList}
                              toolbarOptions={toolbarOptions.options}
                              toolbarInline={toolbarOptions.inlineOption}
                              htmlString={mentionify(responseMessage,mentionList.suggestions)}
                              onChange={(data: any) => setResponseMessage(dementionify(data,mentionList.suggestions))}
                              wrapperClassName="htmleditor-wrapper"
                              editorClassName="editor--with-mention automation-editor-height"
                            />
                          }
                          
                          <Divider size={3} className='pt-8' />
                        </div>
                      </div>
                    )}


                    {isConnected &&
                      <>
                        {!multiInbox && <Header as="h3" content={t('notification-center.default-locale')} className="py-2 defaultStyle" />}
                        {!multiInbox && <Dropdown items={cultures} onChange={(e, p) => setLocale(p.value['key'])} value={cultures.find(c => c.key === locale)?.header} inverted fluid style={{ background: globalTheme.siteVariables.colorScheme.default.background }} />}
                        <Header as="h3" className="pt-8 defaultStyle" content={t('notification-center.file-size')} />
                        <Text className="pt-1 pb-2" content={t('notification-center.file-size-info')} />
                        <div className="flex items-cener">
                          <Input type="number" step="1" min="0" value={minFileSize} placeholder="0" inverted onChange={(event: any) => setMinFileSize(event.target.value)} className="py-2 text-right" />
                          <Text className="p-2 self-center" content="KB" />
                        </div>
                        <Header as="h3" className="pt-8 defaultStyle" content={t('automation.automation-labels.exclude-footer')} />
                        <span className="pt-1 pb-2" dangerouslySetInnerHTML={{ __html: t('notification-center.exclude-footer-details', { color: globalTheme.siteVariables.colorScheme.brand.foregroundActive })}} />
                        <Checkbox label={t('notification-center.dont-show-footer-message')} toggle onChange={toggleExcludeFooter} checked={excludeFooter} />
                        {!multiInbox && <Header as="h3" className="pt-8 pb-2 defaultStyle" content={t('notification-center.ticket-deflection-optional')} />}
                        {!multiInbox && <Checkbox label={t('notification-center.enable-ticket-deflection')} toggle onChange={toggleUseDeflection} checked={useDeflection} />}
                        {!multiInbox && multiDept && <>
                          <Header as="h3" className="pt-8 pb-2 defaultStyle" content={t('notification-center.multideptpicker-optional')} />
                          <p className="text-sm pb-4">{t('notification-center.multideptpicker-subcopy')}</p>
                          <div>
                            <Checkbox label={t('bot-configuration.enduser-enable-multideptpicker')} toggle onChange={toggleEnableMultiDepartmentPicker} checked={enableMultiDepartmentPicker} />
                            {enableMultiDepartmentPicker && (
                              <div className="pt-4">
                                <Text content={t('bot-configuration.enduser-select-team-message')} weight="bold" />
                                <Input value={multiDepartmentPickerMessage} inverted fluid placeholder={t('bot-configuration.enduser-select-team-placeholder')} onChange={(event: any) => setMultiDepartmentPickerMessage(event.target.value)} className="py-2" />
                              </div>
                            )}
                          </div>
                        </>}
                      </>
                    }
                  </Flex>
                )}
              />
            </EmailPageWrapper>)}
        </>)}
    </>;
  }

  return (
    <CheckLogin onSignedIn={loadPage}>
      <Helmet>
        <title>{isNotificationCenter ? t('notification-center.notification-center') : t('notification-center.email-connector')} - Tikit</title>
      </Helmet>
      <div className="hidden md:block">
        {apiStatus.status === LOADING ? (<LoadingScreen message={apiStatus.msg} />) : getPageContent()}
      </div>
      <MobileSettingsView />
    </CheckLogin>
  );
};
