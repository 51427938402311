import {
  TActionGroups,
  TToolbarInteraction
} from '@fluentui/react-teams/lib/esm/components/Toolbar/Toolbar';
import pick from 'lodash/pick';
import React, { useEffect, useState } from 'react';
import {
  columnKey, IRow, ITableProps, KBTable, TSelected,
  TTableInteraction
} from './KBTable';

import { useBoolean } from '@fluentui/react-hooks';
import {
  AcceptIcon,
  AddIcon, Alert, Button, Checkbox, CloseIcon, Dialog, Dropdown, DropdownItemProps, EditIcon, Flex, gridCellWithFocusableElementBehavior, gridNestedBehavior, Input, LinkIcon, MenuButton, MoreIcon, ProviderConsumer, SearchIcon, Table, Text,
  ThemePrepared,
  TrashCanIcon
} from '@fluentui/react-northstar';
import update from 'immutability-helper';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { useHistory } from 'react-router-dom';
import AppCSS from '../App.module.css';
import { AppKBStateContext, useSetKBState } from '../AppKBState';
import { KBData } from '../AppState';
import KBCSS from '../kb.module.css';
import { ObjectSize } from '../shared/common/Helpers';
import { EmptyData } from '../shared/components/EmptyData';
import { Toolbar } from '../shared/components/Toolbar';
import { EditorComponent } from '../shared/components/Wysiwyg/Editor';
import { kbDataService, KBStateService } from '../shared/services/kb.service';
import { platformService } from '../shared/services/platform.service';
import { FILTERS_STRINGS, LICENSE_FEATURE, PRODUCT_LICENSE } from '../shared/utils/constants';
import EmptySVG from './../../svg/empty.svg';
import { getCachedFeature } from '../shared/cache/FeatureCache';
import { toolbarButtonColor } from '../tikit/ticketHelper';
import { useTranslation } from 'react-i18next';
import { PlatformUser } from '../shared/interfaces/platformuser.interface';
import { _getUserAvatar } from '../shared/components/table/ClassDetailList';
import remarkBreaks from 'remark-breaks';
import { toast } from 'react-toastify';

export type TListInteraction = TTableInteraction | TToolbarInteraction;

interface IfEmptyProps {
  header: string;
  subHeader: string;
}

export interface IListProps extends ITableProps {
  emptySelectionActionGroups: TActionGroups;
  filters?: columnKey[];
  filtersSingleSelect?: boolean;
  find?: boolean;
  ifEmpty?: IfEmptyProps;
  onInteraction?: (interaction: TListInteraction) => void;
  templates: Array<any>;
}

export let KBList = (props: IListProps) => {
  const {t} = useTranslation();
  const tableProps = pick(props, [
    'title',
    'columns',
    'rows',
    'selectable',
    'truncate',
    'onInteraction'
  ]);
  // Row selection and common actions
  const [selectedRows, setSelectedRows] = useState<TSelected>(new Set());
  const [isUserLicensed, setIsUserLicensed] = useState<boolean>(false);
  
  useEffect(() => {
    (async () => setIsUserLicensed((await getCachedFeature(PRODUCT_LICENSE.AnalystAccess))))();
  }, []);

  const onSelectedChange = (selected: TSelected) => {
    setSelectedRows(selected);
    return selected;
  };
  
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
  const filterMap: { [filterId: string]: [columnKey, string] } = {};
  const [findQuery, setFindQuery] = useState<RegExp | string | null>(null);
  const handleFindChange = (event: any) => {
    onFindQueryChange(event.target.value);
  };
  const onFindQueryChange = (query: string): string => {
    if (query.length > 0) {
      try {
        setFindQuery(new RegExp(query, 'imu'));
      } catch (_err) {
        setFindQuery(query);
      }
    } else setFindQuery(null);
    return query;
  };

  const rowPassesFilters = (row: IRow): boolean => {
    if (selectedFilters.length === 0) return true;
    else {
      return (
        selectedFilters.findIndex(filterId => {
          const [columnKey, value] = filterMap[filterId];
          return row[columnKey] === value;
        }) > -1
      );
    }
  };

  const rowPassesFind = (row: IRow): boolean => {
    if (findQuery) {
      return (
        Object.keys(row).findIndex(columnKey => {
          const value = row[columnKey];
          return (
            typeof value === 'string' &&
            (typeof findQuery === 'string'
              ? value.includes(findQuery)
              : findQuery.test(value))
          );
        }) > -1
      );
    } else return true;
  };

  const filterBy = (row: IRow): boolean => {
    return rowPassesFilters(row) && rowPassesFind(row);
  };

  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  const history = useHistory();

  const onToolbarInteraction = (interaction: TToolbarInteraction) => {
    if (interaction.action === 'toggle-menu') {
      setMenuOpen(!menuOpen);
    }
  };

  const [kbQuery, setKBQuery] = useState<string>('');

  const filterKB = (kb: KBData[], users: PlatformUser[], templates: any[]) => {
    let filteredkb = kb.filter(data => {
      let qFilter = data.questions.filter(q =>
        q.toLowerCase().includes(kbQuery.toLowerCase())
      );
      let createdByFullName = users && users.find(u => u.Id == data.createdById) ? users.find(u => u.Id == data.createdById).FullName : null;
      let modifiedByFullName = users && users.find(u => u.Id == data.modifiedById) ? users.find(u => u.Id == data.modifiedById).FullName : null;
      let templateName = templates.find(x => x.Name.toLowerCase().includes(kbQuery.toLowerCase()));
      if (qFilter.length > 0) {
        return data;
      }
      if (data.answer.toLowerCase().includes(kbQuery.toLowerCase())) {
        return data;
      }
      if (data.status.toLowerCase().includes(kbQuery.toLowerCase().replace(/ /g, ''))) {
        return data;
      }
      if (createdByFullName && createdByFullName.toLowerCase().includes(kbQuery.toLowerCase())) {
        return data;
      }
      if (modifiedByFullName && modifiedByFullName.toLowerCase().includes(kbQuery.toLowerCase())) {
        return data;
      }
      if (templateName && data.answer.toLowerCase().includes(`:${templateName.Id}`)) {
        return data;
      }
    });
    return filteredkb;
  };
  return (
    <AppKBStateContext.Consumer>
      {state => {
        return (
          <ProviderConsumer
            render={globalTheme => (
              <>
                {(!props.hasOwnProperty('title') ||
                  props['title'] == 'Users') && (
                    <Toolbar
                      globalTheme={globalTheme}
                      title={`Settings > ${props['title']}`}
                      onInteraction={onToolbarInteraction}
                      saveText="Update All Users"
                      saveCommand={props['syncAllUsers']}
                      breadcrumbs={props['breadcrumbs']}
                    />
                  )}
                {(!props.hasOwnProperty('title') ||
                  props['title'] == 'Groups') && (
                    <Toolbar
                      globalTheme={globalTheme}
                      title={`Settings > ${props['title']}`}
                      breadcrumbs={props['breadcrumbs']}
                      addNew={true}
                      onInteraction={onToolbarInteraction}
                      addNewContent={
                        <Button
                          disabled={!isUserLicensed}
                          icon={<AddIcon />}
                          text
                          primary
                          content="New Group"
                          onClick={() => {
                            var path = `/settings/groups?id=0`;
                            history.push(path);
                          }}
                          style={{
                            color: toolbarButtonColor(isUserLicensed, globalTheme.siteVariables.colorScheme),
                            height: '52px'
                          }}
                        />
                      }
                    />
                  )}
                {(!props.hasOwnProperty('title') ||
                  props['title'] == 'Tags') && (
                    <Toolbar
                      globalTheme={globalTheme}
                      title={`Settings > ${props['title']}`}
                      breadcrumbs={props['breadcrumbs']}
                      addNew={true}
                      onInteraction={onToolbarInteraction}
                      addNewContent={
                        <Button
                          disabled={!isUserLicensed}
                          icon={<AddIcon />}
                          text
                          primary
                          content="New Tag"
                          onClick={() => {
                            var path = `/settings/tags?id=0`;
                            history.push(path);
                          }}
                          style={{
                            color: toolbarButtonColor(isUserLicensed, globalTheme.siteVariables.colorScheme),
                            height: '52px'
                          }}
                        />
                      }
                    />
                  )}
                <Flex
                  style={{
                    marginTop: '-1.25rem',
                    height: 'calc(100vh - 150px)'
                  }}
                >
                  <Flex.Item
                    grow
                    styles={{ paddingTop: '1.25rem', overflow: 'auto' }}
                  >
                    <div>
                      {props.ifEmpty && (
                        <>
                          {ObjectSize(tableProps.rows) === 0 && (
                            <>
                              {tableProps.title === 'KB' && (
                                <div className={`flex justify-between`}>
                                  <div className={`ml-4 mb-2`}>
                                    <AddQnADialog isUserLicensed={isUserLicensed} />
                                  </div>
                                  <div className={`mr-4 mb-2`}>
                                    <Input icon={<SearchIcon />} inverted placeholder={t('knowledge.search-placeholder')} value={kbQuery} onChange={(e, p) => setKBQuery(p.value)} />
                                  </div>
                                </div>)}
                              <EmptyData
                                headerText={props.ifEmpty.header}
                                subheaderText={props.ifEmpty.subHeader}
                                SVGIcon={<EmptySVG width={200} height={200} />}
                              />
                            </>
                          )}

                          {ObjectSize(tableProps.rows) > 0 && (
                            <>
                              {tableProps.title !== 'KB' && (
                                <>
                                  <div className={`flex justify-end`}>
                                    <div
                                      style={{
                                        marginRight: '16px',
                                        marginBottom: '16px'
                                      }}
                                    >
                                      <Input
                                        icon={<SearchIcon />}
                                        inverted
                                        placeholder={t('knowledge.search-placeholder')}
                                        onChange={handleFindChange}
                                      />
                                    </div>
                                  </div>
                                  <div className={`mb-5`}>
                                    <KBTable
                                      {...tableProps}
                                      {...{ onSelectedChange, filterBy }}
                                      aria-live="polite"
                                      id="fluentui-teams__list-content"
                                      aria-label="List content"
                                    />
                                  </div>
                                </>
                              )}
                              {tableProps.title === 'KB' && (
                                <>
                                  <div className={`flex justify-between`}>
                                    <div className={`ml-4 mb-2`}>
                                      <AddQnADialog isUserLicensed={isUserLicensed} />
                                    </div>
                                    <div className={`mr-4 mb-2`}>
                                      <Input icon={<SearchIcon />} inverted placeholder={t('knowledge.search-placeholder')} value={kbQuery} onChange={(e, p) => setKBQuery(p.value)} />
                                    </div>
                                  </div>
                                  <div className={`mb-5`}>
                                    <KnowledgeList rows={filterKB(state.knowledgeBase, state.users, props.templates)} templates={props.templates} isUserLicensed={isUserLicensed} users={state.users} />
                                  </div>
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                      {!props.ifEmpty && (
                        <>
                          <div className={`flex justify-end`}>
                            <div
                              style={{
                                marginRight: '16px',
                                marginBottom: '16px'
                              }}
                            >
                              <Input
                                icon={<SearchIcon />}
                                inverted
                                placeholder={t('knowledge.search-placeholder')}
                                onChange={handleFindChange}
                              />
                            </div>
                          </div>

                          <KBTable
                            {...tableProps}
                            {...{ onSelectedChange, filterBy }}
                            aria-live="polite"
                            id="fluentui-teams__list-content"
                            aria-label="List content"
                          />
                        </>
                      )}
                    </div>
                  </Flex.Item>
                </Flex>
              </>
            )}
          />
        );
      }}
    </AppKBStateContext.Consumer>
  );
};

interface KLProps {
  rows: KBData[];
  users: PlatformUser[];
  templates: Array<any>;
  isUserLicensed: boolean;
}

const questionsFormatter = (questions: string[], isUserLicensed: boolean) => {
  const firstFiveQuestions = questions.slice(0, 5);
  const remainingQuestions = questions.slice(5);

  return (
    <>
      {firstFiveQuestions.map((q, i) => (
        <div key={i}>
          <Text content={q} color={`${isUserLicensed && 'brand'}`} />
        </div>
      ))}
      {remainingQuestions.length > 0 && (
        <div>
          <Text content="..." />
        </div>
      )}
    </>
  );
}

interface EditQnAProps {
  questions: string[];
  answer: string;
  id: number;
  open?: boolean;
  status: string;
  agentOnly: boolean;
  onInteraction?: (interaction: any) => void;
  templates: Array<any>;
}

interface QnAFormProps {
  qnaQuestions: string[];
  globalTheme: ThemePrepared<any>;
  inputOnChange: (props: any, index: number) => void;
  onRemove: (index: number) => void;
  onAddAlternate: () => void;
}

export const QnAForm = ({ qnaQuestions, globalTheme, onRemove, inputOnChange, onAddAlternate }: QnAFormProps) => {
  let keyCounter = 0;
  const {t} = useTranslation();
  return <>
    {
      qnaQuestions.map((q, index) => {
        const key = keyCounter++
        return (
          <Flex key={key}>
            <Flex.Item>
              <Input
                inverted
                fluid
                showSuccessIndicator={false}
                placeholder={t('knowledge.add-knowledge-modal.placeholder')}
                maxLength={1000}
                style={{
                  backgroundColor:
                    globalTheme.siteVariables.colorScheme
                      .default.background2
                }}
                variables={{
                  background:
                    globalTheme.siteVariables.colorScheme
                      .default.background2
                }}
                className={`${KBCSS.TransparentInput} mb-2`}
                value={qnaQuestions[index]}
                key={key}
                onChange={(_e, p) => {
                  inputOnChange(p, index);
                }}
              />
            </Flex.Item>
            <Flex.Item>
              <Button
                icon={<CloseIcon />}
                text
                iconOnly
                title={t('knowledge.add-knowledge-modal.button.remove')}
                onClick={() => {
                  onRemove(index);
                }}
                styles={{
                  alignSelf: 'center'
                }}
              />
            </Flex.Item>
          </Flex>
        );
      })
    }
    <Button
      icon={<AddIcon />}
      text
      content={t('knowledge.add-knowledge-modal.add-alternative-phrasing')}
      className={'mb-2'}
      style={{
        color:
          globalTheme.siteVariables.colorScheme.brand
            .foreground
      }}
      {...{
        onClick: _e => {
          onAddAlternate();
        }
      }}
    />
  </>
}

const GetKb = async() =>{
  let kb = await kbDataService.fetchAPI();
  let values = JSON.parse(kb.data.value);
    values = values.sort(function (a, b) {
    return b["id"] - a["id"];
  });

  return values;
}

const EditQnADialog = (props: EditQnAProps) => {
  const {t} = useTranslation();
  const [qnaQuestions, setQnaQuestions] = useState<string[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [
    useTemplate,
    {
      toggle: toggleUseTemplate,
      setTrue: setTrueUseTemplate,
      setFalse: setFalseUseTemplate
    }
  ] = useBoolean(false);
  const [templates, setTemplates] = useState<Array<DropdownItemProps>>([]);
  const [stringAnswer, setStringAnswer] = useState('');
  const [templateAnswer, setTemplateAnswer] = useState('');
  const [placeholder, setPlaceholder] = useState('');
  const setState = useSetKBState();
  const [hasError, setHasError] = useState(false);
  const [isTemplateDeflectionEnabled, setIsTemplateDeflectionEnabled] = useState(false);
  const [status, setStatus] = useState('');
  const [statuses, setStatuses] = useState<Array<DropdownItemProps>>([]);
  const [agentOnly, setAgentOnly] = useState(false);

  useEffect(() => {
    (async () => setIsTemplateDeflectionEnabled((await getCachedFeature(LICENSE_FEATURE.TemplateDeflection))))();
    setQnaQuestions(props.questions);
    if (
      props.answer.match(/^ApplyTemplate:\d+$/gi) &&
      props.templates.filter(
        item => `ApplyTemplate:${item.Id}` === props.answer
      ).length > 0
    ) {
      setTemplateAnswer(props.answer);
      setTrueUseTemplate();
      setPlaceholder(
        props.templates.filter(
          item => `ApplyTemplate:${item.Id}` === props.answer
        )[0].Name
      );
    } else {
      setStringAnswer(props.answer);
      setFalseUseTemplate();
    }
    setStatus(props.status);
    setStatuses(
      ['Draft', 'In Review', 'Active'].map(item => {
        let itemValue = item.replace(/\s+/g, "");
        return {
          accessibilityItemProps: {
            id: itemValue,
            answerValue: itemValue
          },
          header: item,
          selected: itemValue === props.status
        } as DropdownItemProps;
      })
    );

    setAgentOnly(props.agentOnly)
    setTemplates(
      props.templates.map(item => {
        return {
          accessibilityItemProps: {
            id: item.Id,
            answerValue: `ApplyTemplate:${item.Id}`
          },
          header: item.Name,
          selected: `ApplyTemplate:${item.Id}` === props.answer
        } as DropdownItemProps;
      })
    );
    
    if (props.open) setOpen(props.open);
  }, [props.open]);

  const onClose = () => {
    if (props.onInteraction) {
      props.onInteraction({ event: 'close' });
    }
  };

  return (
    <AppKBStateContext.Consumer>
      {appstate => {
        return (
          <ProviderConsumer
            render={globalTheme => (
              <>
                <Dialog
                  cancelButton={t('knowledge.add-knowledge-modal.button.cancel')}
                  confirmButton={t('knowledge.add-knowledge-modal.button.save')}
                  closeOnOutsideClick={false}
                  open={open}
                  onConfirm={async () => {
                    const answers = useTemplate ? templateAnswer : stringAnswer;
                    setHasError(false);
                    if (qnaQuestions.filter(x => x && x.trim() != '').length <= 0 || answers.trim() === '') {
                      setHasError(true);
                      return;
                    }

                    setState(state => {
                      return { ...state, loadingKB: { message: t('knowledge.loading-knowledge-message'), isLoading: true } };
                    });
                    let kbIndex = appstate.knowledgeBase.findIndex(
                      kb => kb.id === props.id
                    );

                    if (kbIndex >= 0) {
                      let removeEmpty = [];

                      qnaQuestions.forEach(q => {
                        if (q !== '') {
                          removeEmpty.push(q);
                        }
                      });
                                        
                      let updatedKB = update(appstate.knowledgeBase[kbIndex], {
                        answer: { $set: answers },
                        questions: { $set: removeEmpty },
                        status: { $set: status },
                        agentOnly: { $set: agentOnly }
                      });

                      var newData = update(appstate.knowledgeBase, {
                        $splice: [[kbIndex, 1, updatedKB]]
                      });

                      setQnaQuestions(removeEmpty);

                      try {
                        appstate.knowledgeBase[kbIndex].source = (useTemplate ? 'Template' : 'Editorial');
                        let job = await kbDataService.updateSingleKB({ ...appstate.knowledgeBase[kbIndex], id: props.id, answer: answers, questions: removeEmpty, status: status, agentOnly: agentOnly });
                        if (job.status === "succeeded") {
                          let kb = await GetKb();
                          newData[kbIndex].lastUpdatedDateTime = new Date().toISOString();
                          setState(state => { return { ...state, knowledgeBase: kb, tab: 0 }; });
                        }
                      } catch (err) {
                        console.error(err);
                      }

                      setState(state => { return { ...state, loadingKB: { message: '', isLoading: false } }; });
                      setOpen(false);
                    }
                  }}
                  onCancel={() => {
                    setOpen(false);
                    onClose();
                  }}
                  content={
                    <>
                      <div>
                        {hasError && (
                          <Alert
                            content={t('knowledge.add-knowledge-modal.error.no-data')}
                            danger
                            visible
                          />
                        )}
                        <label className={`mb-2`}>{t('knowledge.add-knowledge-modal.phrases')}</label>
                        <QnAForm
                          globalTheme={globalTheme}
                          qnaQuestions={qnaQuestions}
                          inputOnChange={(p, index) => {
                            let questions = [...qnaQuestions];
                            questions[index] = p.value;
                            setQnaQuestions(questions);
                          }}
                          onRemove={(index) => {
                            if (qnaQuestions.length > 1) {
                              let questions = qnaQuestions
                                .slice(0, index)
                                .concat(qnaQuestions.slice(index + 1));
                              setQnaQuestions(questions);
                            }
                          }} 
                          onAddAlternate={() => {
                            setQnaQuestions([...qnaQuestions, '']);
                          }}
                          />
                      </div>
                        <span style={{position: 'absolute', bottom: '35px'}}>
                          <Checkbox label={t('knowledge.knowledge-table.agent-only')}
                            checked={agentOnly} 
                            onChange={() => setAgentOnly(!agentOnly)}/>
                        </span>
                      <div>
                        <label className={`mb-2`}>{t('knowledge.knowledge-table.status')}</label>
                        <div>
                          <Dropdown style={{background: globalTheme.siteVariables.colorScheme.default.background2 }}
                            fluid
                            items={statuses}
                            value={statuses.filter(x => x.selected)}
                            onChange={(e, p) => { updateStatus(p, setStatus, statuses);}}
                          />
                        </div>
                      </div>
                      <div className={`dialog-editor`}>
                        <Flex column>
                          <Flex.Item>
                            <label className={`mb-2`}>{t('knowledge.add-knowledge-modal.respond-with')}</label>
                          </Flex.Item>
                          {isTemplateDeflectionEnabled &&
                          <Flex.Item>
                            <Checkbox
                              label={t('knowledge.add-knowledge-modal.use-template')}
                              toggle
                              onChange={() => {
                                toggleUseTemplate();
                              }}
                              checked={useTemplate}
                            />
                          </Flex.Item>
                          }
                        </Flex>
                        {!useTemplate && (<EditorComponent markdownString={stringAnswer} onChange={(data: any) => { setStringAnswer(data); }} maxLength={25000} />)}
                        {useTemplate && (<Dropdown fluid inverted style={{background: globalTheme.siteVariables.colorScheme.default.background2 }} 
                          items={templates} defaultValue={templates.filter(x => x.selected)} placeholder={placeholder} onChange={(e, p) => { setTemplateAnswer(p.value ? p.value['accessibilityItemProps'].answerValue : ''); }} />)}
                      </div>
                    </>
                  }
                  header={t('knowledge.edit-modal-header')}
                />
              </>
            )}
          />
        );
      }}
    </AppKBStateContext.Consumer>
  );
};

const KBListOptions = (props: {
  id: number;
  isTemplate: boolean;
  onInteraction?: (interaction: any) => void;
  isUserLicensed: boolean;
}) => {
  const {t} = useTranslation();
  const setState = useSetKBState();
  const [open, setOpen] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  
  const getDirectLink = async () => {
    await navigator.clipboard.writeText(`https://${window.location.host}/knowledge/${(props.isTemplate ? 'template' : 'article')}/${props.id}`);
    toast.success(t('common.link-copied'));
  }

  return (
    <ProviderConsumer render={globalTheme => (
    <>
      <Dialog
        className={AppCSS.confirmDialog}
        cancelButton={t('knowledge.add-knowledge-modal.button.cancel')}
        confirmButton={t('common.buttons.confirm')}
        open={openDialog}
        onConfirm={() => {
          setState(state => {
            return {
              ...state,
              loadingKB: {
                message:
                  t('knowledge.loading-knowledge-message'),
                isLoading: true
              }
            };
          });
          kbDataService
            .deleteAPI([props.id])
            .then(() => {
              setState(state => {
                return {
                  ...state,
                  deletedKB: [...state.deletedKB, props.id],
                  knowledgeBase: state.knowledgeBase.filter(
                    kb => kb.id !== props.id
                  ),
                  tab: 0
                };
              });
            })
            .catch(e => console.log(e))
            .finally(() => {
              setState(state => {
                return {
                  ...state,
                  loadingKB: {
                    message: '',
                    isLoading: false
                  }
                };
              });
            });
        }}
        onCancel={() => {
          setOpenDialog(false);
        }}
        header={`${t('knowledge.delet-kb-message')}${props.id}`}
        content={''}
      />
      <MenuButton
        open={open}
        onOpenChange={(e, { open }) => {
          if (props.isUserLicensed) setOpen(open);
        }}
        onMenuItemClick={(e, p) => {
          if (props.isUserLicensed) {
            if (p.index === 0) {
              // delete
              setOpenDialog(true);
            } else if (p.index === 1) {
              props.onInteraction({ event: 'openDialog', id: props.id });
            } else if (p.index === 2) {
              getDirectLink();
            }
          }
        }}
        trigger={
          <Button
            style={props.isUserLicensed ? {} : {color: globalTheme.siteVariables.colorScheme.default.foregroundDisabled}}
            disabled={!props.isUserLicensed}
            tabIndex={-1}
            icon={<MoreIcon />}
            circular
            text
            iconOnly
            title={t('knowledge.knowledge-table.more-options')}
          />
        }
        menu={[
          <span key='trash-icon'>
            <TrashCanIcon disabled={!props.isUserLicensed} outline /> &nbsp;{t('knowledge.knowledge-table.delete')}
          </span>,
          <span key='edit-icon'>
            <EditIcon disabled={!props.isUserLicensed} outline /> &nbsp;{t('knowledge.knowledge-table.edit')}
          </span>,
          <span key='link-icon'>
            <LinkIcon outline /> &nbsp;{t('common.direct-link')}
          </span>
        ]}
      />
    </>
    )} />
  );
};

const KnowledgeList = (props: KLProps) => {
  const {t} = useTranslation();
  const [rowState, setRowState] = useState<{ id: number; isOpen: boolean }[]>([]);

  const header = {
    key: 'header',
    items: [
      { content: t('knowledge.knowledge-table.phrases'), style: { maxWidth: '23%' }, key: 'phrases' },
      { content: t('knowledge.knowledge-table.response'), style: { maxWidth: '31%' }, key: 'response' },
      { content: t('knowledge.knowledge-table.status'), style: { maxWidth: '5%' }, key: 'status' },
      { content: t('knowledge.knowledge-table.createdBy'), style: { maxWidth: '11%' }, key: 'createdById' },
      { content: t('knowledge.knowledge-table.modifiedBy'), style: { maxWidth: '11%' }, key: 'modifiedById' },
      { content: t('knowledge.knowledge-table.lastModified'), style: { maxWidth: '9%' }, key: 'lastUpdatedDateTime' },
      { content: t('knowledge.knowledge-table.agent-only'), style: { maxWidth: '5%' }, key: 'agentOnly' },
      { key: 'more options', 'aria-label': t('knowledge.knowledge-table.options'), style: { maxWidth: '5%' } },
    ]
  };

  const optionInteraction = (p: { event: string; id: number }) => {
    if (p.event === 'openDialog') {
      toggleEditDialog(p.id, true);
    }
  };

  const moreOptionCell = (id: number, answer: string) => {
    let templateId = null;
    const match = /^ApplyTemplate:(?<id>\d+)$/i.exec(answer);
    if (match)
      templateId = parseInt(match.groups['id'], 10);

    return {
      content: <KBListOptions id={templateId ?? id} isTemplate={match != null} onInteraction={optionInteraction} isUserLicensed={props.isUserLicensed} />,
      truncateContent: true,
      accessibility: gridCellWithFocusableElementBehavior
    };
  };

  const toggleEditDialog = (id: number, state: boolean) => {
    let getRowIndex = rowState.findIndex(rs => rs.id === id);

    let updateValues = update(rowState[getRowIndex], {
      isOpen: { $set: state }
    });
    let newRowState = update(rowState, {
      $splice: [[getRowIndex, 1, updateValues]]
    });
    setRowState(newRowState);
  };

  const rowFormatter = (
    rows: KBData[],
    templates: any[],
    theme?: 'dark' | 'light'
  ) => {
    let tablerows = [];
    
    rows.forEach(r => {
      let findRowState = rowState.find(rs => rs.id === r.id);
      if (!findRowState) {
        let newRowState = rowState;
        newRowState.push({ id: r.id, isOpen: false });
        setRowState(newRowState);
      }
      tablerows.push({
        key: r.id,
        items: [
          {
            key: `${r.id}-1`,
            content: (
              <>
                <div
                  onClick={() => { if (props.isUserLicensed) toggleEditDialog(r.id, true); }}
                  className={`${props.isUserLicensed ? 'cursor-pointer' : 'cursor-default'}`}
                >
                  {questionsFormatter(r.questions, props.isUserLicensed)}
                </div>
                
                <EditQnADialog
                  questions={r.questions}
                  answer={r.answer}
                  id={r.id}
                  onInteraction={e => {
                    if (e.event === 'close') {
                      toggleEditDialog(r.id, false);
                    }
                  }}
                  open={rowState.find(rs => rs.id === r.id).isOpen}
                  templates={templates}
                  status={r.status}
                  agentOnly={r.agentOnly}
                />
              </>
            ),
            style: { maxWidth: '23%' }
          },
          {
            key: `${r.id}-2`,
            content: r.answer.match(/^ApplyTemplate\:\d+$/gi) ? (
              templates.filter(x => `ApplyTemplate:${x.Id}` === r.answer)
                .length > 0 ? (
                <Text
                  content={`Template: ${templates.filter(
                    x => `ApplyTemplate:${x.Id}` === r.answer
                  )[0].Name
                    }`}
                />
              ) : (
                 <Text content={truncateString(r.answer, 350)} style={{ wordBreak: 'break-word' }}/>
              )
            ) : (
              <Markdown remarkPlugins={[remarkGfm, remarkBreaks]} components={kbOptions} className={theme === 'dark' ? KBCSS.fromMDDark : KBCSS.fromMDLight}>
                  {truncateString(r.answer, 350)}
                </Markdown>
            ),
            style: { maxWidth: '31%' }
          },
          {
            key: '1-3',
            content: <span>{r.status.replace(/([a-z])([A-Z])/g, '$1 $2')}</span>,
            style: { maxWidth: '5%' }
          },
          {
            key: '1-4',
            content: <span>{getUserAvatar(props.users && props.users.find(u => u.Id == r.createdById) ? props.users.find(u => u.Id == r.createdById) : null) }</span>,
            style: { maxWidth: '11%' }
          },
          {
            key: '1-5',
            content: <span>{getUserAvatar(props.users && props.users.find(u => u.Id == r.modifiedById) ? props.users.find(u => u.Id == r.modifiedById) : null)}</span>,
            style: { maxWidth: '11%' }           
          },
          {
            key: '1-6',
            content: <span>{new Date(r.lastUpdatedDateTime).toLocaleString(undefined, { dateStyle: 'short', timeStyle: 'short' })}</span>,
            style: { maxWidth: '9%' }
          },
          {
            key: '1-7',
            content: r.agentOnly ? <AcceptIcon style={{paddingLeft: '25px'}}/> : "",
            style: { maxWidth: '5%' }
          },
          {
            key: '1-8',
            ...moreOptionCell(r.id, r.answer),
            style: { maxWidth: '5%' }
          }
        ]
      });
    });
    return tablerows;
  };

  return (
    <ProviderConsumer
      render={globalTheme => (
        <Table
          variables={{
            cellContentOverflow: 'none',
            rowHeight: 'auto'
          }}
          header={header}
          rows={rowFormatter(
            props.rows,
            props.templates,
            globalTheme.siteVariables.colorScheme.default.background === '#fff'
              ? 'light'
              : 'dark'
          )}
          aria-label="Nested navigation"
          accessibility={gridNestedBehavior}
          className={KBCSS.kbTable}
          styles={{
            gridTemplateColumns: header.items.map(item => item.style?.maxWidth || 'auto').join(' '),
          }}
        />
      )}
    />
  );
};

const kbStyle = {
  whiteSpace: 'normal',
  wordBreak: 'break-word'
};

const kbOptions = {
  a(props) {
    const { node, ...rest } = props;
    return <a target="_blank" style={{ textDecoration: 'underline', kbStyle }} {...rest} />;
  },
  ol(props) {
    const { node, ...rest } = props;
    return <ol className='defaultStyle' {...rest} />;
  },
  ul(props) {
    const { node, ...rest } = props;
    return <ul className='defaultStyle' {...rest} />;
  },
  div(props) {
    const { node, ...rest } = props;
    return <div style={kbStyle} {...rest} />;
  },
  span(props) {
    const { node, ...rest } = props;
    return <span style={kbStyle} {...rest} />;
  },
  p(props) {
    const { node, ...rest } = props;
    return <p style={kbStyle} {...rest} />;
  }
};

const truncateString = (str, num) => {
  if (str.length <= num) {
    return str;
  }
  let truncatedStr = str.slice(0, num);
  const nextSpaceIndex = str.indexOf(' ', num);

  if (nextSpaceIndex !== -1) {
    truncatedStr = str.slice(0, nextSpaceIndex);
  }

  return truncatedStr + " ...";
}

const getUserAvatar = (user: any) => {
  if (!user)
    return '';
  return (
  <div>
    {_getUserAvatar(user.Email, user.FullName)}
    &nbsp;
    {user.FullName}
  </div>
  );
}

const updateStatus = (props: any, setStatus: any, statuses: any) => {
  let statusValue = props.value ? props.value['accessibilityItemProps'].answerValue : '';
  props.status = statusValue;
  setStatus(statusValue);
  for (const key in statuses) {
    if (statuses[key]['accessibilityItemProps'].answerValue === statusValue) {
      statuses[key].selected = true;
    } else {
      statuses[key].selected = false;
    }
  }
};

const AddQnADialog = (props: any) => {
  const {t} = useTranslation();
  const api = new platformService();
  const setState = useSetKBState();
  const [hasError, setHasError] = useState(false);
  const [addOpen, { setTrue: setOpenTrue, setFalse: setOpenFalse }] = useBoolean(false);

  const [stringAnswer, setStringAnswer] = useState('');
  const [templateAnswer, setTemplateAnswer] = useState('');
  const [useTemplate, { toggle: toggleUseTemplate, setFalse: setFalseUseTemplate }] = useBoolean(false);
  const [templates, setTemplates] = useState<Array<DropdownItemProps>>([]);
  const [editorIsFocus, setEditorIsFocus] = useState(false);
  const [isTemplateDeflectionEnabled, setIsTemplateDeflectionEnabled] = useState(false);
  const [status, setStatus] = useState('Draft');
  const [statuses, setStatuses] = useState<Array<DropdownItemProps>>([]);
  const [agentOnly, setAgentOnly] = useState(false);
  
  const [toBeCreatedKB, setToBeCreatedKB] = useState({
    questions: [''],
    answer: '',
    status: status,
    agentOnly: agentOnly
  });

  const fetchTemplates = async () => {
    try {
      const result = await api.getTicketTemplates(`${FILTERS_STRINGS.FILTERS_URL_PARAMS} and NoCustomForm eq false and CardResponseJson ne '' and CardResponseJson ne null`);

      const templates = result.data.value.map(item => {
        return {
          accessibilityItemProps: {
            id: item.Id,
            answerValue: `ApplyTemplate:${item.Id}`
          },
          header: item.Name,
          selected: false
        } as DropdownItemProps;
      });

      setTemplates(templates);
    } catch {
      setTemplates([]);
    }
  };

  useEffect(() => { 
    fetchTemplates(); 
    (async () => setIsTemplateDeflectionEnabled((await getCachedFeature(LICENSE_FEATURE.TemplateDeflection))))();
    
    setStatus(props.status);
    setStatuses(
      ['Draft', 'In Review', 'Active'].map(item => {
        let itemValue = item.replace(/\s+/g, "");
        return {
          accessibilityItemProps: {
            id: itemValue,
            answerValue: itemValue
          },
          header: item,
          selected: itemValue === status
        } as DropdownItemProps;
      })
    );
  }, []);

  let keyCounter = 0;

  return (<AppKBStateContext.Consumer>
    {state => {
      return (
        <ProviderConsumer
          render={globalTheme => (
          <Dialog
            cancelButton={t('knowledge.add-knowledge-modal.button.cancel')}
            confirmButton={t('knowledge.add-knowledge-modal.button.add')}
            open={addOpen}
            onOpen={() => { if (props.isUserLicensed) setOpenTrue() }}
            className={`${KBCSS.EditQnADialog}`}
            closeOnOutsideClick={false}
            content={
              <>
                <div>
                  {hasError && (<Alert content={t('knowledge.add-knowledge-modal.error.no-data')} danger visible />)}
                  <label className={`mb-2`}>{t('knowledge.add-knowledge-modal.phrases')}</label>
                  {toBeCreatedKB.questions.map(
                    (q, index) => {
                      const key = keyCounter++
                      return (
                        <Flex key={key}>
                          <Flex.Item>
                            <Input
                              fluid
                              inverted
                              showSuccessIndicator={false}
                              placeholder={t('knowledge.add-knowledge-modal.placeholder')}
                              maxLength={1000}
                              style={{
                                backgroundColor:
                                  globalTheme.siteVariables
                                    .colorScheme.default
                                    .background2
                              }}
                              variables={{
                                background:
                                  globalTheme.siteVariables
                                    .colorScheme.default
                                    .background2
                              }}
                              className={`${KBCSS.TransparentInput} mb-2`}
                              value={
                                toBeCreatedKB.questions[
                                index
                                ]
                              }
                              onChange={(e, p) => {
                                let questions =
                                  toBeCreatedKB.questions;
                                questions[index] = p.value;
                                setToBeCreatedKB({
                                  ...toBeCreatedKB,
                                  questions: questions
                                });
                              }}
                            />
                          </Flex.Item>
                          <Flex.Item>
                            <Button
                              icon={<CloseIcon />}
                              text
                              iconOnly
                              title={t('knowledge.add-knowledge-modal.button.remove')}
                              onClick={() => {
                                if (
                                  toBeCreatedKB.questions
                                    .length > 1
                                ) {
                                  let questions =
                                    toBeCreatedKB.questions
                                      .slice(0, index)
                                      .concat(
                                        toBeCreatedKB.questions.slice(
                                          index + 1
                                        )
                                      );
                                  setToBeCreatedKB({
                                    ...toBeCreatedKB,
                                    questions: questions
                                  });
                                } else {
                                  setToBeCreatedKB({
                                    ...toBeCreatedKB,
                                    questions: ['']
                                  });
                                }
                              }}
                              styles={{
                                alignSelf: 'center'
                              }}
                            />
                          </Flex.Item>
                        </Flex>
                      );
                    }
                  )}
                  <Button
                    icon={<AddIcon />}
                    text
                    content={t('knowledge.add-knowledge-modal.add-alternative-phrasing')}
                    className={'mb-2 cui-btn'}
                    style={{
                      color:
                        globalTheme.siteVariables
                          .colorScheme.brand.foreground
                    }}
                    {...{
                      onClick: () => {
                      setToBeCreatedKB({
                          ...toBeCreatedKB,
                          questions: [
                            ...toBeCreatedKB.questions,
                            ''
                          ]  
                        });
                      }
                    }}
                  />
                </div>
                  <span style={{position: 'absolute', bottom: '35px'}}>
                    <Checkbox label={t('knowledge.knowledge-table.agent-only')} 
                      checked={agentOnly} 
                      onChange={(e, p) => { setAgentOnly(p.checked); setToBeCreatedKB({...toBeCreatedKB, agentOnly: p.checked})}}/>
                  </span>
                <div>
                <span>
                    <label className={`mb-2`}>{t('knowledge.knowledge-table.status')}</label>
                    <Dropdown style={{background: globalTheme.siteVariables.colorScheme.default.background2, width: 'inherit'}}
                      fluid
                      items={statuses}
                      value={statuses.filter(x => x.selected)}
                      onChange={(e, p) => { updateStatus(p, setStatus, statuses); }}
                      defaultValue={statuses[0]}
                    />
                  </span>
                </div>
                <div className={`dialog-editor`}>
                  <Flex column>
                    <Flex.Item>
                      <label className={`mb-2`}>
                        {t('knowledge.add-knowledge-modal.respond-with')}
                      </label>
                    </Flex.Item>
                    { isTemplateDeflectionEnabled &&
                    <Flex.Item>
                      <Checkbox
                        label={t('knowledge.add-knowledge-modal.use-template')}
                        toggle
                        onChange={() => {
                          toggleUseTemplate();
                          setHasError(false);
                          setEditorIsFocus(true);
                          setToBeCreatedKB({
                            ...toBeCreatedKB,
                            answer: useTemplate
                              ? templateAnswer
                              : stringAnswer
                          });
                        }}
                        checked={useTemplate}
                      />
                    </Flex.Item>
                  }
                  </Flex>
                  {!useTemplate && (
                    <EditorComponent
                      isFocus={editorIsFocus}
                      markdownString={stringAnswer}
                      onChange={(data: any) => {
                        setStringAnswer(data);
                      }}
                      maxLength={25000}
                      useBorder={true}
                    />
                  )}
                  {useTemplate && (
                    <Dropdown
                      fluid
                      position="above"
                      search
                      style={{background: globalTheme.siteVariables.colorScheme.default.background2 }}
                      items={templates}
                      onChange={(e, p) => {
                        setTemplateAnswer(
                          p.value
                            ? p.value[
                              'accessibilityItemProps'
                            ].answerValue
                            : ''
                        );
                      }}
                    />
                  )}
                </div>
              </>
            }
            header={t('knowledge.add-knowledge')}
            trigger={
                <div>
                  <div className={`md:hidden`}>
                    <Button disabled={!props.isUserLicensed} icon={<AddIcon />} primary size="small" content={<Text size="smallest" content={t('knowledge.add-qna')} />} />
                  </div>
                  <div className={`hidden md:block`}>
                    <Button disabled={!props.isUserLicensed} icon={<AddIcon />} primary content={t('knowledge.add-knowledge')} />
                  </div>
                </div>
            }
            onCancel={() => {
              setToBeCreatedKB({...toBeCreatedKB, questions: [''], answer: '' });
              setStringAnswer("");
              setFalseUseTemplate();
              setOpenFalse();
            }}
            onConfirm={() => {
              const answer = useTemplate ? templateAnswer : stringAnswer;
              let source = useTemplate ? 'Template' : 'kbnew';
              setHasError(false);
              if (
                toBeCreatedKB.questions.filter(x => x && x.trim() != '').length <= 0 ||
                answer.trim() === ''
              ) {
                setHasError(true);
                return;
              }

              setOpenFalse();
              let newRow: KBData = KBStateService.addOneRow(
                toBeCreatedKB.questions,
                answer,
                state.knowledgeBase
              );
              newRow.source = source;
              newRow.status = status || 'Draft';
              newRow.agentOnly = agentOnly;
                            
              setState(state => {
                return {
                  ...state,
                  loadingKB: {
                    message:
                      t('knowledge.loading-knowledge-message'),
                    isLoading: true
                  }
                };
              });
              kbDataService.addAPI([newRow]).then(() => {
                (async () => {
                  let updatedKb = await GetKb();
                  setState(state => {
                  return {
                    ...state,
                    knowledgeBase: updatedKb, 
                    tab: 0
                  };
                });
              })();
            })
            .catch(e => console.log(e))
            .finally(() => {
              setState(state => {
                return {
                  ...state,
                  loadingKB: {
                    message: '',
                    isLoading: false
                  }
                };
              });
            });
            setToBeCreatedKB({
              questions: [''],
              answer: '',
              status: 'Draft',
              agentOnly: agentOnly
            });
          }}
          />
          )} />
      );
    }}
  </AppKBStateContext.Consumer>);
};