
import React, { useEffect, useState, createRef, useCallback, useRef } from 'react';
import * as microsoftTeams from "@microsoft/teams-js";
import { Flex, Loader, List, ThemePrepared, Button, FilesUploadIcon, Input, ListItem, Text, Skeleton, Alert, Grid, gridHorizontalBehavior, Checkbox, Layout, Image } from '@fluentui/react-northstar';
import { ExclamationTriangleIcon } from '@fluentui/react-icons-northstar';
import Dropzone, { DropzoneRef, FileRejection } from 'react-dropzone';
import { Icon, Link, mergeStyleSets } from '@fluentui/react';
import EmptySVG from '../../../../../svg/empty.svg';
import DatabaseSVG from '../../../../../svg/fileTypes/light/Database.svg';
import DocumentManagementSVG from '../../../../../svg/fileTypes/light/DocumentManagement.svg';
import ExcelDocumentSVG from '../../../../../svg/fileTypes/light/ExcelDocument.svg';
import FilePDBSVG from '../../../../../svg/fileTypes/light/FilePDB.svg';
import FileSQLSVG from '../../../../../svg/fileTypes/light/FileSQL.svg';
import MailSVG from '../../../../../svg/fileTypes/light/Mail.svg';
import PDFSVG from '../../../../../svg/fileTypes/light/PDF.svg';
import Photo2SVG from '../../../../../svg/fileTypes/light/Photo2.svg';
import PowerPointDocumentSVG from '../../../../../svg/fileTypes/light/PowerPointDocument.svg';
import SlideshowSVG from '../../../../../svg/fileTypes/light/Slideshow.svg';
import TextDocumentSVG from '../../../../../svg/fileTypes/light/TextDocument.svg';
import WordDocumentSVG from '../../../../../svg/fileTypes/light/WordDocument.svg';
import ZipFolderSVG from '../../../../../svg/fileTypes/light/ZipFolder.svg';
import FileCodeSVG from '../../../../../svg/fileTypes/light/FileCode.svg';
import FileBugSVG from '../../../../../svg/fileTypes/light/FileBug.svg';
import PageSVG from '../../../../../svg/fileTypes/light/Page.svg';
import DarkDatabaseSVG from '../../../../../svg/fileTypes/dark/Database.svg';
import DarkDocumentManagementSVG from '../../../../../svg/fileTypes/dark/DocumentManagement.svg';
import DarkExcelDocumentSVG from '../../../../../svg/fileTypes/dark/ExcelDocument.svg';
import DarkFilePDBSVG from '../../../../../svg/fileTypes/dark/FilePDB.svg';
import DarkFileSQLSVG from '../../../../../svg/fileTypes/dark/FileSQL.svg';
import DarkMailSVG from '../../../../../svg/fileTypes/dark/Mail.svg';
import DarkPDFSVG from '../../../../../svg/fileTypes/dark/PDF.svg';
import DarkPhoto2SVG from '../../../../../svg/fileTypes/dark/Photo2.svg';
import DarkPowerPointDocumentSVG from '../../../../../svg/fileTypes/dark/PowerPointDocument.svg';
import DarkSlideshowSVG from '../../../../../svg/fileTypes/dark/Slideshow.svg';
import DarkTextDocumentSVG from '../../../../../svg/fileTypes/dark/TextDocument.svg';
import DarkWordDocumentSVG from '../../../../../svg/fileTypes/dark/WordDocument.svg';
import DarkZipFolderSVG from '../../../../../svg/fileTypes/dark/ZipFolder.svg';
import DarkFileCodeSVG from '../../../../../svg/fileTypes/dark/FileCode.svg';
import DarkFileBugSVG from '../../../../../svg/fileTypes/dark/FileBug.svg';
import DarkPageSVG from '../../../../../svg/fileTypes/dark/Page.svg';
import { platformService } from '../../../services/platform.service';
import { EmptyData } from '../../EmptyData';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { FileProps } from '../EditTicketAttachments';
import { checkInTeams } from '../../../../App';
import { ITeamsConfig } from '../../../../config/TeamsConfig';
import { themeNames } from '@fluentui/react-teams';
import SlateEditor, { slateToMd } from '../../Wysiwyg/SlateEditor';
import { appState, useSetState } from '../../../../AppState';
import { getUser } from '../../../../AuthConfig';

const uploadFailedMessage = "Upload failed.";

interface TicketPageProps {
  globalTheme: ThemePrepared<any>,
  ticketId: number,
  filesToUpload: File[];
  onInteraction?: (interaction: any) => void;
  teamsConfig: ITeamsConfig;
  isEndUserPage: boolean;
  onClose?: () => void;
}

export const NewAttachmentFlowContent = (props: TicketPageProps) => {
  const state = appState();
  const setState = useSetState();
  const { t } = useTranslation();
  const { ticketId, globalTheme } = props;
  const api = new platformService();
  const [files, setFiles] = useState<FileProps[]>([]);
  const droppedRef = useRef<FileProps[]>();
  droppedRef.current = files;
  const [uploadRenamedFiles, setUploadRenamedFiles] = useState<any[]>([]);
  const [triageChannel, setTriageChannel] = useState("");
  const [optionalMessage, setOptionalMessage] = useState([{
    children: [{ text: '' }],
  }
  ]);
  const [sendToChat, setSendToChat] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isAttaching, setIsAttaching] = useState(false);
  const [uploadDone, setUploadDone] = useState(false);
  const [assignees, setAssignees] = useState([]);
  let isInTeams = checkInTeams();

  const classNames = mergeStyleSets({
    fileLink: {
      fontWeight: 600,
      color: 'var(--mgt-theme-brand-foreground1)',
      ':hover, :active, :focus': {
        color: 'var(--mgt-theme-brand-foreground1) !important'
      }
    },
    alertLink: {
      color: 'inherit',
      fontWeight: 600,
      ':hover, :active, :focus': {
        color: 'inherit'
      }
    },
    fileIcon: {
      color: 'var(--mgt-theme-default-foreground2)',
      marginRight: ".5rem"
    },
    disabledContainer: {
      pointerEvents: 'none',
      opacity: 0.4
    }
  });

  const setFilesToUpload = (items: File[], files: FileProps[]) => {
    const renamedFiles = renameDuplicateFile(items, files);
    setUploadRenamedFiles(f => renamedFiles.concat(f));

    setFiles((currentItems: FileProps[]) => {
      let newItems: FileProps[] = renamedFiles.map(item =>
        Object.assign(item, {
          FileName: item.name,
          FileExtension: (item.ext.length > 0) ? item.ext : getExtension(item.name),
          FileSize: item.file.size,
          isUploading: false
        } as FileProps));

      let allFiles = newItems.concat(currentItems);
      allFiles = allFiles.map(file => {
        if (!file.iconElement)
          file.iconElement = getFileIcon(file.FileExtension, file, globalTheme)
        return file;
      });
      return allFiles;
    });
  }

  useEffect(() => {
    (async () => {
      const currentUser = getUser();
      let result = props.teamsConfig?.TenantId ? props.teamsConfig : (await api.getTeamsBotConfiguration())?.data ?? {};
      setTriageChannel(result?.TriageChannel || "");

      if (props.filesToUpload?.length > 0 && files?.length == 0) {
        setFilesToUpload(props.filesToUpload, state.uploadingState.uploadedFiles);
      }

      if (state?.usersInRole?.length > 0)
        setAssignees(state.usersInRole.filter(a => a.UserName !== currentUser && a.Id !== -1));
      else if (!props.isEndUserPage) {
        try {
          const users = await api.getUsersInRole();
          setAssignees(JSON.parse(users.data.value).filter(a => a.UserName !== currentUser && a.Id !== -1));
        } catch {
          // Ignore this call if it fails, mentions just won't work.
        }
      }

      setIsLoading(false)
    })()
  }, []);

  const dropzoneRef = createRef<DropzoneRef>();
  const onDropAccepted = (items: File[]) => {
    setFilesToUpload(items, state.uploadingState.uploadedFiles.concat(files));
  }

  const pastedImageFile = (file: File) => {
    let idx = droppedRef.current.filter(f => f.FileName.startsWith("image-")).length + 1;
    const result = new File([file], `image-${idx.toString().padStart(3, "0")}.png`, { type: file.type });

    setFilesToUpload([result], state.uploadingState.uploadedFiles.concat(files));
  }

  const getExtension = (filename: string) => {
    return filename.substring(filename.lastIndexOf("."));
  };

  const onAttachTicket = (isPrivate?: boolean) => {
    setIsAttaching(true);

    setFiles((currentItems: FileProps[]) => {
      let newItems: FileProps[] = currentItems.map(item =>
        Object.assign(item, {
          ModifiedDate: (new Date()).toString(),
          isUploading: true
        } as FileProps));
      if (!isInTeams) {
        setState((s) => ({
          ...s,
          uploadingState: {
            ...s.uploadingState,
            uploadedFiles: newItems.concat(s.uploadingState.uploadedFiles)
          }
        }))
      }
      return newItems;
    });

    (async () => {
      const uploadedFiles: FileProps[] = await api.uploadTicketFiles(ticketId, uploadRenamedFiles, slateToMd(optionalMessage), isPrivate, props.isEndUserPage, sendToChat);
      let errorOcurred = false;
      setFiles((currentItems) => {
        return currentItems.map(item => {
          if (item.isUploading) {
            const uploads = uploadedFiles.filter(file => file.FileName == item.FileName);
            if (uploads.length > 0) {
              item = uploads[0];
              item.isUploading = false;
            } else {
              item.isUploadFailed = true;
              item.isUploading = false;
              item.uploadFailedMessage = `${uploadFailedMessage}`;
              item.iconElement = getFileIcon('failed', item, globalTheme);
              errorOcurred = true;
            }
          }
          return item;
        });
      });

      if (!isInTeams) {
        if (!errorOcurred)
          toast.success(t('ticket-details.edit-ticket-view.ticket-updated-successfully', { ticketId: ticketId }));
        else
          toast.error(`Ticket #${ticketId}: ${uploadFailedMessage}`);

        setState((s) => ({
          ...s,
          uploadingState: {
            ...s.uploadingState,
            uploadedFiles: s.uploadingState.uploadedFiles.map(u => {
              const f = uploadedFiles.filter(x => x.FileName == u.FileName);
              return (f.length > 0) ? f[0] : u;
            })
          }
        }))

        if (props.onInteraction) props.onInteraction({ event: 'onAttachmentsUpdate' });
      }
      setUploadDone(true);
    })()
  }

  const onDropRejected = (fileRejections: FileRejection[]) => {
    setFiles((currentItems: FileProps[]) => {
      let newItems: FileProps[] = fileRejections.map(rejected => {
        let item = rejected.file;
        return Object.assign(item, {
          FileName: item.name,
          isUploadFailed: true,
          uploadFailedMessage: `${uploadFailedMessage} ${rejected.errors.map(x => x.message).join(" ")}`,
          iconElement: getFileIcon('failed', item, globalTheme)
        } as FileProps)
      });
      return newItems.concat(currentItems);
    });
  }

  const renderSkeleton = (num: number) => Array.from(Array(num)).map((r, index) => (
    <ListItem {...{
      index: index,
      media: <Skeleton animation="wave"><Skeleton.Shape width="32px" height="32px"></Skeleton.Shape></Skeleton>,
      content: <Skeleton animation="wave"><Skeleton.Line></Skeleton.Line></Skeleton>
    }} />
  ));

  const renderFiles = files.map((file, index) => (
    <ListItem {...{
      index: index,
      media: ((file.isUploading)
        ? <Loader size="smallest" inline />
        : (file.iconElement) ? file.iconElement : getFileIcon((file.isUploadFailed) ? 'failed' : file.FileExtension, file, globalTheme)
      ),
      header: <Text content={getTruncatedFilename(file.FileName, 50)} />,
      headerMedia: (''),
      endMedia: <Grid accessibility={gridHorizontalBehavior} columns={1}>

        <Button
          disabled={isAttaching}
          icon={<Icon iconName="Delete" className={'text-sm'} />}
          iconOnly
          text
          title={t('ticket-details.edit-ticket-form.attachments.button.delete')}
          style={{
            color: !isAttaching ? globalTheme.siteVariables.colorScheme.brand.foreground : globalTheme.siteVariables.colorScheme.default.foregroundDisabled
          }}
          onClick={() => {
            setFiles((currentItems: FileProps[]) => {
              return currentItems.filter(item => item.FileName != file.FileName);
            });
            setUploadRenamedFiles((currentItems: FileProps[]) => {
              return currentItems.filter(item => item.FileName != file.FileName);
            });
          }}
        />
      </Grid>,
      content: bytesToSize(file.FileSize),
      style: {
        borderBottomWidth: 'thin',
        height: '4rem',
        display: file.isHidden ? 'none' : 'flex',
        cursor: 'auto'
      },
      hidden: file.isHidden
    }} />)
  );

  const onChangeEditorComponentCallback = useCallback((data: any) => { setOptionalMessage(data) }, [setOptionalMessage]);
  const closeTaskModlue = () => {
    if (isInTeams)
      microsoftTeams.dialog.url.submit();
    else if (props.onClose)
      props.onClose();
  };

  microsoftTeams.app.getContext().then((context: microsoftTeams.app.Context) => {
    setSendToChat(context.chat?.id?.length > 0)
  });

  return (<>
    {(isLoading) && (
      <>
        <Skeleton animation="wave">
          <Flex space="between" style={{ margin: "1.25rem 0", alignItems: "center" }}>
            <Flex.Item className="extended-toolbar__far-side">
              <Flex vAlign="center">
                <Skeleton.Button />
              </Flex>
            </Flex.Item>
            <Flex.Item>
              <Skeleton.Input />
            </Flex.Item>
          </Flex>
        </Skeleton>
        <List>
          {renderSkeleton(10)}
        </List>
      </>
    )}
    {(!isLoading) && (
      <>
        {(triageChannel.length < 1) && (
          <Flex.Item>
            <Alert danger icon={<ExclamationTriangleIcon />} content={<>
              <Text content="Upload disabled. Please make sure you've added Tikit to a Team and set the Group (Channel) correctly. " />
              <Link target="_blank" href="https://help.tikit.ai/faq/how-do-i-add-tikit-to-a-team/" className={classNames.alertLink}>Learn more.</Link></>}
            />
          </Flex.Item>
        )}
        <Flex.Item grow>
          <Dropzone ref={dropzoneRef} noClick noKeyboard onDropAccepted={onDropAccepted} onDropRejected={onDropRejected} disabled={triageChannel.length < 1 || isAttaching}>
            {({ getRootProps, getInputProps, isDragActive }) => {
              return (
                (<div >
                  <div {...getRootProps({ className: 'dropzone overflow-auto' })} >
                    <input {...getInputProps()} />
                    {isDragActive && (
                      <Flex className={`h-screen w-full`} style={{
                        height: "72vh",
                        left: 0,
                        backgroundColor: globalTheme.siteVariables.colorScheme.default.background,
                        opacity: "0.5",
                        zIndex: 2,
                        cursor: "pointer",
                        position: "absolute",
                        justifyContent: "center"
                      }}>
                        {(files.length > 0) && (
                          <Text content="Drop files here..." size="largest" weight="bold" style={{ alignSelf: "center" }} />
                        )}
                      </Flex>
                    )}
                    {(files.length > 0) && (
                      <>
                        <Flex.Item>
                          <Flex className='mt-2' style={{ background: globalTheme.siteVariables.colorScheme.brand.background1, color: globalTheme.siteVariables.colorScheme.default.foreground, padding: '4px 8px 4px 8px', justifyContent: 'space-between' }}>
                            {!isAttaching && (<Text content={t('ticket-details.edit-ticket-form.attachments.empty.sub-header-text')} weight="semibold" />)}
                            {isAttaching && (<Text content={`${!uploadDone ? `${(t('ticket-details.edit-ticket-form.attachments.upload-in-progress')).replace("...", '')}` : `${t('ticket-details.edit-ticket-form.attachments.button.upload')} ${t('common.done')}`}, ${(t('requests.dialog.close-window')).toLowerCase()}`} weight="semibold" />)}
                          </Flex>
                        </Flex.Item>
                        <Flex.Item>
                          <Flex space="between" style={{ margin: "1.25rem 0", alignItems: "center" }}>
                            <Flex.Item className="extended-toolbar__far-side">
                              <Text content={`${t('ticket-details.edit-ticket-form.attachments.files-to-upload')} (${files.length})`} size="large" weight="semibold" />
                            </Flex.Item>
                            <Flex.Item >
                              <Flex vAlign="center">
                                <Button text icon={<FilesUploadIcon />} content={t('ticket-details.edit-ticket-form.attachments.button.upload')} title={t('ticket-details.edit-ticket-form.attachments.button.upload')} onClick={() => {
                                  if (dropzoneRef.current)
                                    dropzoneRef.current.open();
                                }}
                                  style={{
                                    color: !isAttaching ? globalTheme.siteVariables.colorScheme.brand.foreground : globalTheme.siteVariables.colorScheme.default.foregroundDisabled
                                  }}
                                  disabled={triageChannel.length < 1 || isAttaching}
                                />
                              </Flex>
                            </Flex.Item>
                          </Flex>
                        </Flex.Item>
                      </>
                    )}
                    {(files.length > 0 && !isLoading) && (
                      <>
                        <div style={{ height: 'calc(100vh - 550px)', minHeight: '200px' }} className='overflow-auto py-2'>
                          <List truncateHeader variables={{ selectableFocusHoverBackgroundColor: globalTheme.siteVariables.colorScheme.default.background }}>
                            {renderFiles}
                          </List>
                        </div>
                        <Flex column className='mt-4'>
                          <Input.Label style={{ color: !isAttaching ? globalTheme.siteVariables.colorScheme.default.foreground : globalTheme.siteVariables.colorScheme.default.foregroundDisabled }}>{t('ticket-details.edit-ticket-form.attachments.optional-message')}</Input.Label>
                          <div className={`border ${(isAttaching || files.length == 0) ? classNames.disabledContainer : ""}`}>
                            <SlateEditor
                              readOnly={isAttaching || files.length == 0}
                              onChange={onChangeEditorComponentCallback}
                              slateValue={optionalMessage}
                              usersInMention={assignees}
                              onImagePaste={pastedImageFile}
                              isFocus={true}
                              noImage={true}
                              minHeight='5rem'
                            />
                          </div>
                          <Flex hAlign='end' className='mt-3' gap="gap.small">
                            <Button content={t('common.buttons.close')} onClick={closeTaskModlue} />
                            {!props.isEndUserPage && (
                              <Button disabled={isAttaching || files.length == 0} content={t('ticket-details.edit-ticket-form.attachments.add-internal-attachment')} tinted onClick={() => onAttachTicket(true)}
                                style={isAttaching || files.length == 0 ? { color: globalTheme.siteVariables.colorScheme.default.foregroundDisabled, background: globalTheme.siteVariables.theme === themeNames.Dark && isInTeams ? globalTheme.siteVariables.colorScheme.default.background : '' } : {}}
                              />
                            )}
                            <Button disabled={isAttaching || files.length == 0} content={t('ticket-details.edit-ticket-form.attachments.attach-to-ticket')} primary onClick={() => onAttachTicket(false)}
                              style={isAttaching || files.length == 0 ? { color: globalTheme.siteVariables.colorScheme.default.foregroundDisabled, background: globalTheme.siteVariables.theme === themeNames.Dark && isInTeams ? globalTheme.siteVariables.colorScheme.default.background : '' } : {}} />
                          </Flex>
                        </Flex>
                      </>
                    )}
                    {(files.length == 0) && (
                      <div className={`py-10`} style={{ height: '72vh' }} >
                        <EmptyData
                          headerText={t('ticket-details.edit-ticket-form.attachments.empty.header-text')}
                          subheaderText={t('ticket-details.edit-ticket-form.attachments.empty.sub-header-text')}
                          SVGIcon={<EmptySVG height={120} width={120} />}
                          Extras={<Button className='mt-2'
                            content={t('ticket-details.edit-ticket-form.attachments.button.upload')}
                            primary
                            onClick={() => {
                              if (dropzoneRef.current)
                                dropzoneRef.current.open();
                            }}
                          />}
                        />
                      </div>
                    )}
                  </div>
                </div>)
              );
            }}
          </Dropzone>
        </Flex.Item>
      </>
    )}
  </>);

}

const iconProperties = { width: '20px', height: '20px' }

const iconByFileExtension: { name: string, types: string, element: JSX.Element, darkElement: JSX.Element }[] = [
  { name: "Page", types: "unknown", element: <PageSVG {...iconProperties} />, darkElement: <DarkPageSVG {...iconProperties} /> },
  { name: "FileBug", types: "failed", element: <FileBugSVG {...iconProperties} />, darkElement: <DarkFileBugSVG {...iconProperties} /> },
  { name: "Photo2", types: "jpg gif png jpeg bmp ico tif tiff psd ps ai", element: <Photo2SVG {...iconProperties} />, darkElement: <DarkPhoto2SVG {...iconProperties} /> },
  { name: "WordDocument", types: "doc docm docx odt wpd", element: <WordDocumentSVG {...iconProperties} />, darkElement: <DarkWordDocumentSVG {...iconProperties} /> },
  { name: "TextDocument", types: "txt rtf", element: <TextDocumentSVG {...iconProperties} />, darkElement: <DarkTextDocumentSVG {...iconProperties} /> },
  { name: "ExcelDocument", types: "xls xlsm xlsx ods csv", element: <ExcelDocumentSVG {...iconProperties} />, darkElement: <DarkExcelDocumentSVG {...iconProperties} /> },
  { name: "PowerPointDocument", types: "key pps ppt pptx odp", element: <PowerPointDocumentSVG {...iconProperties} />, darkElement: <DarkPowerPointDocumentSVG {...iconProperties} /> },
  { name: "ZipFolder", types: "zip rar pkg 7z deb arj", element: <ZipFolderSVG {...iconProperties} />, darkElement: <DarkZipFolderSVG {...iconProperties} /> },
  { name: "PDF", types: "pdf", element: <PDFSVG {...iconProperties} />, darkElement: <DarkPDFSVG {...iconProperties} /> },
  { name: "FileSQL", types: "sql", element: <FileSQLSVG {...iconProperties} />, darkElement: <DarkFileSQLSVG {...iconProperties} /> },
  { name: "Database", types: "dat db dbf mdb accdb", element: <DatabaseSVG {...iconProperties} />, darkElement: <DarkDatabaseSVG {...iconProperties} /> },
  { name: "Mail", types: "email  eml emlx msg oft ost pst vcf", element: <MailSVG {...iconProperties} />, darkElement: <DarkMailSVG {...iconProperties} /> },
  { name: "FilePDB", types: "bat bin exe apk wsf jar ps1 tar", element: <FilePDBSVG {...iconProperties} />, darkElement: <DarkFilePDBSVG {...iconProperties} /> },
  { name: "DocumentManagement", types: "bat bin exe apk wsf jar ps1 tar", element: <DocumentManagementSVG {...iconProperties} />, darkElement: <DarkDocumentManagementSVG {...iconProperties} /> },
  { name: "FileCode", types: "json xml class cs js css less tsx html htm xhtml asp aspx", element: <FileCodeSVG {...iconProperties} />, darkElement: <DarkFileCodeSVG {...iconProperties} /> },
  { name: "Slideshow", types: "avi flv m4v mkv mov mp4 mpg mpeg wmv", element: <SlideshowSVG {...iconProperties} />, darkElement: <DarkSlideshowSVG {...iconProperties} /> },
]

export const getFileIconName = (ext: string) => {
  const docType = ext.substring(ext.lastIndexOf(".") + 1).toLowerCase();
  let icon = iconByFileExtension.filter(icon => icon.types.indexOf(docType) >= 0);
  return (icon.length > 0) ? icon[0].name : iconByFileExtension[0].name
}

export const getImageElement = (attachment: FileProps) => {
  let link = "";
  try {
    link = (!attachment.DriveItemGroupId) ? URL.createObjectURL(attachment["file"]) : `${attachment.ThumbnailUrl}`;
  } catch { }
  const el = <Layout
    styles={{
      maxWidth: '20px',
    }}
    renderMainArea={() => (
      <Image
        fluid
        src={link}
      />
    )}
  />;
  return el;
}

export const getFileIcon = (ext: string, file: any, theme: any) => {
  let docType = ""
  if (ext)
    docType = ext.substring(ext.lastIndexOf(".") + 1).toLowerCase();
  let icon = iconByFileExtension.filter(icon => icon.types.indexOf(docType) >= 0);
  if (icon.length > 0 && icon[0].name === "Photo2")
    return getImageElement(file);
  let element = (icon.length > 0) ? icon[0] : iconByFileExtension[0];
  return (theme.siteVariables.theme === themeNames.Dark) ? element.darkElement : element.element;
}

const bytesToSize = (bytes: number) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = 2;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

const renameDuplicateFile = (items: File[], files: FileProps[]) => {
  return items.map(file => {
    const filenames = files.map(item => {
      if (item.isUploadFailed) return -1;

      let myRegexp = /^(.*?)\((\d+)\)\.(.*)$/g;
      let match = myRegexp.exec(item.FileName);
      if (match != null) {
        const name = match[1];
        const num = match[2];
        const ext = match[3];
        if (file.name.replace(/\s/g, '') == `${name}.${ext}`.replace(/\s/g, ''))
          return (num) ? parseInt(num) : 0;
        else
          return -1;
      }

      if (file.name.replace(/\s/g, '') == item.FileName.replace(/\s/g, ''))
        return 0;
      else
        return -1;
    });
    let filename = file.name;
    let ext = "";
    let max = Math.max.apply(Math, filenames);
    if (max > -1) {
      const lastIndex = file.name.lastIndexOf(".");
      const ext = file.name.substring(lastIndex);
      filename = `${file.name.substring(0, lastIndex)} (${max + 1})${ext}`;
    }
    return {
      file: file,
      name: filename,
      ext: ext
    }
  });
}

export const getTruncatedFilename = (filename: string, length?: number) => {
  const len = length ?? 21;
  const split = filename.split('.');
  let name = split[0];
  const extension = split[1];

  if (name.length > len) {
    name = name.substring(0, len);
    filename = name + '....' + extension;
  }

  return filename;
} 